.menu {

    ul {
        margin: 0;
    }

    // reset default menu / list styles provided by BS
    li {
        display: block;
        list-style: none;
        position: relative;

        a {
            transition: 0.2s;
        }
    }

    // Horizontal menus
    &__horizontal {

        li {
            display: inline-block;

            &:not(:last-child):after {
                content: "/";
                position: absolute;
                right: -11px;
                top: 0;
                speak: none;
            }

            a {
                padding: 8px;
                margin: 0 0 0 8px;
                background-color: transparent;

                html.no-touchevents &:hover {
                    background-color: $whe-yellow-1;
                }
            }

            &.current-menu-item a,
            &.current-menu-ancestor a {
                background-color: $whe-yellow-1;
            }
        }
    }

    // Vertical menus
    &__vertical {

        li {
            display: block;
        }
    }


    // Utility menu
    &__utility-navigation {

        li {

            a {
                @extend .bask__reg-4;
                color: $whe-black;

                html.no-touchevents &:hover {
                    color: $whe-black;
                }
            }

            &.current-menu-item a,
            &.current-menu-ancestor a {
                color: $whe-black;
            }
        }
    }


    // Breadcrumb menu
    &__breadcrumb-navigation {
        margin-left: -6px;

        a,
        .breadcrumb_last {
            @extend .frank__cond-3;
            position: relative;
            padding: 8px;
            margin-right: 6px;
            transition: 0.2s;

            &:not(:last-child):after {
                content: "/";
                position: absolute;
                right: -10px;
                top: 3px;
                speak: none;
            }

            html.no-touchevents &:hover {
                background-color: $whe-yellow-1;
                color: $whe-black;
            }
        }

        .breadcrumb_last {
            font-weight: bold;

            html.no-touchevents &:hover {
                background-color: transparent;
                color: $whe-black;
            }
        }
    }


    // Section menu ("On this page"), coupled with date navigation /tabs
    &__section-navigation,
    &__team-navigation,
    &__filter-navigation {

        li {

            @include media-breakpoint-down(md) {
                display: block;
            }

            &:first-child {

                a {
                    margin-left: 0;
                }
            }

            &:after {

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            a {
                @extend .frank__cond-2;

                @include media-breakpoint-down(md) {
                    margin-left: 0;
                }

                html.no-touchevents &:hover,
                &.active {
                    background-color: $whe-white;
                    color: $whe-black;
                }
            }

            &.current-menu-item a,
            &.current-menu-ancestor a {
                background-color: $whe-white;
                color: $whe-black;
            }
        }
    }


    &__filter-navigation {

        .filter-title {
            @extend .frank__hvy-3;
        }
    }


    // Section menu ("On this page")
    &__section-navigation {
        margin-bottom: 100px;

        @include media-breakpoint-down(md) {
            margin-bottom: 60px;
        }

        #menu-load-message {
            display: none;

            &.loading {
                display: block;
            }
        }

        .menu-container {
            background-color: fade-out($whe-orange-2, 0.40);
            padding: 30px 0 20px;
            position: relative;

            @include media-breakpoint-down(md) {
                padding: 15px 0 5px;
                display: none;
            }

            &.active {
                display: block;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                left: 50%;
                bottom: -40px;
                width: 0;
                height: 0;
                border-left: 60px solid transparent;
                border-right: 60px solid transparent;
                border-top: 40px solid fade-out($whe-orange-2, 0.40);
                speak: none;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

        header {
            z-index: 5;
        }

        .nav-title {
            @extend .frank__med-2;
            color: $whe-purple-1;
            margin: 0;
            z-index: 5;

            .toggle__section-nav {
                display: none;

                @include media-breakpoint-down(md) {
                    display: inline-block;
                }
            }
        }

        li {

            @include media-breakpoint-down(md) {
                display: block;
            }

            a {
                margin-left: 7px;

                @include media-breakpoint-down(md) {
                    margin-left: 0;
                }
            }

            &:after {

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &:not(:last-child) {
                @include media-breakpoint-down(md) {
                    margin-bottom: 5px;
                }
            }
        }
    }


    // Team menu
    &__team-navigation {

        li {

            @include media-breakpoint-down(md) {
                display: block;
            }

            a {
                margin-left: 7px;

                @include media-breakpoint-down(md) {
                    margin-left: 0;
                }
            }

            &:after {

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &:not(:last-child) {
                @include media-breakpoint-down(md) {
                    margin-bottom: 5px;
                }
            }
        }
    }


    // Secondary menu
    &__secondary-navigation {
        // mix-blend-mode: multiply;
        background-color: $whe-grey-2;
        padding: 0 25px 0;

        li {

            a {
                @extend .bask__reg-4;
                color: $whe-white;
                display: inline-block;
                padding: 30px 8px 8px;

                html.no-touchevents &:hover {
                    color: $whe-black;
                }
            }

            &:not(:last-child):after {
                top: auto;
                bottom: 5px;
            }

            &.current-menu-item a,
            &.current-menu-ancestor a {
                color: $whe-black;
            }
        }
    }


    // Main menu
    &__main-navigation {
        background-color: fade-out($whe-purple-1, 0.1);
        padding: 25px 0 25px 30px;

        li {

            a {
                @extend .frank__med-4;
                color: $whe-white;
                padding: 0 8px 3px;

                html.no-touchevents &:hover {
                    background-color: transparent;
                    color: $whe-yellow-1;

                    span {
                        border-bottom: 1px solid $whe-yellow-1;
                    }
                }
            }

            &:not(:last-child):after {
                color: $whe-yellow-1;
            }

            &.current-menu-item a,
            &.current-menu-ancestor a {
                background-color: transparent;
                color: $whe-yellow-1;

                span {
                    border-bottom: 1px solid $whe-yellow-1;
                }
            }
        }
    }


    // Sub-nav menu ("In this section")
    &__sub-navigation {
        mix-blend-mode: multiply;
        background-color: fade-out($whe-grey-3, 0.3);
        padding: 10px 0;

        @include media-breakpoint-down(sm) {
            margin: 0;
            padding-bottom: 0;
        }

        .nav-title {
            @extend .frank__med-2;
            color: $whe-purple-1;
            margin: 0;
            padding: 0 10px;

            .toggle__sub-nav {
                display: none;

                @include media-breakpoint-down(sm) {
                    display: inline-block;
                }
            }

            @include media-breakpoint-down(sm) {
                padding-bottom: 10px;
            }
        }

        >ul {
            padding: 15px 0 10px 0;
            position: relative;
            z-index: 0;

            // For mobile, the list should be hidden by default, then shown if active
            // active class triggered by the sub-nav button / toggle
            @include media-breakpoint-down(sm) {
                margin: 0;
                padding: 15px 0 10px 0;
                display: none;
            }

            &:after {
                background-color: fade-out($whe-blue-2, 0.6);
                content: "";
                display: block;
                position: absolute;
                margin: 0 -15px;
                left: 0;
                right: 0;
                top: 0;
                width: calc(100% + 30px);
                height: 100%;
                speak: none;
                z-index: -1;

                @include media-breakpoint-down(sm) {
                    margin: 0;
                    width: 100%;
                }
            }

            &.active {
                display: block;
            }
        }

        li {

            a {
                @extend .frank__med-4;
                color: $whe-purple-1;
                display: inline-block;
                padding: 5px 15px 5px 10px;
                line-height: 1;

                html.no-touchevents &:hover {
                    background-color: $whe-white;
                }
            }

            &.current-menu-item a,
            &.current-menu-ancestor a {
                background-color: $whe-white;
            }
        }
    }


    // Main navigation - footer
    &__main-footer-navigation {
        background-color: $whe-purple-1;

        li {

            a {
                @extend .frank__med-1;
                color: $whe-white;
                display: inline-block;
                line-height: 1;
                margin-bottom: 25px;

                html.no-touchevents &:hover {

                    span {
                        border-bottom: 1px solid $whe-yellow-1;
                    }
                }
            }

            &.current-menu-item a span,
            &.current-menu-ancestor a span {
                border-bottom: 1px solid $whe-yellow-1;
            }
        }

        // For mobile menu + when mobile overlay is active on the body
        body.overlay-active & {

            @include media-breakpoint-down(md) {
                display: block;
            }
        }
    }


    // Secondary + Utility Navigations - Footer
    &__secondary-footer-navigation,
    &__utility-footer-navigation {
        background-color: $whe-purple-1;

        li {

            a {
                @extend .bask__reg-3;
                color: $white;
                display: inline-block;
                line-height: 1;
                margin-bottom: 20px;

                html.no-touchevents &:hover {

                    span {
                        border-bottom: 1px solid $whe-yellow-1;
                    }
                }
            }

            &.current-menu-item a span,
            &.current-menu-ancestor a span {
                border-bottom: 1px solid $whe-yellow-1;
            }
        }

        // For mobile menu + when mobile overlay is active on the body
        body.overlay-active & {

            li {

                @include media-breakpoint-down(md) {
                    display: block;
                }
            }
        }
    }


    // Social Navigation - Footer
    &__social-footer-navigation {
        background-color: $whe-purple-1;

        @include media-breakpoint-down(md) {
            text-align: center;
        }

        li {

            @include media-breakpoint-down(md) {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: $spacer * 2;
                }
            }

            @include media-breakpoint-down(md) {

                &:not(:last-child) {
                    margin-right: $spacer;
                }
            }

            a {
                @extend .bask__reg-3;
                color: $white;
                display: inline-block;
                line-height: 1;
                margin-bottom: 15px;
                padding: 2px 0;

                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                }

                svg,
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 3px;
                    vertical-align: top;
                }

                .label {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                html.no-touchevents &:hover {

                    >span {
                        border-bottom: 1px solid $whe-yellow-1;
                    }
                }
            }
        }
    }


    // Footer Navigation
    &__footer-navigation {
        @extend .menu__main-navigation;
        mix-blend-mode: unset;
        background-color: $whe-grey-1;
        padding: 0;

        li {
            margin-right: $spacer / 1.5;

            &:not(:last-child):after {
                color: $whe-white;
            }

            a {
                margin: 0 8px 0 0;
                padding: 0;
            }

            &.current-menu-item a,
            &.current-menu-ancestor a {
                color: $whe-yellow-1;
            }
        }
    }


    // Section blocks menu
    &__section-blocks {

        li {
            position: relative;
            padding-left: $spacer;

            &:before {
                content: "";
                display: block;
                background: svg-load('icon-li.svg');
                width: 9px;
                height: 9px;
                position: absolute;
                left: 0;
                top: 9px;
            }

            a {
                @extend .frank__med-4;
                color: $whe-purple-1;

                html.no-touchevents &:hover span {
                    border-bottom: 1px solid $whe-purple-1;
                }
            }
        }
    }
}