.link {
  // default, inline link style
  &__inline {
    border-bottom: 1px solid $whe-yellow-1;

    html.no-touchevents &:hover {
      color: $whe-purple-1;
      border-bottom: 1px solid $whe-purple-1;
    }
  }

  &__section {
    @extend .frank__med-4;
    color: $whe-black;
    border-bottom: 1px solid $whe-purple-1;

    // When section link is the link itself
    &[target="_blank"] {
      &:after {
        content: "";
        display: inline-block;
        background-image: url("../img/svg/icon-external.svg");
        width: 14px;
        height: 14px;
        margin-left: 5px;
      }
    }

    // When section link is a containing element (most likely a span)
    a[target="_blank"] {
      &:after {
        content: "";
        display: inline-block;
        background-image: url("../img/svg/icon-external.svg");
        width: 14px;
        height: 14px;
        margin-left: 5px;
      }
    }

    html.no-touchevents &:hover {
      color: $whe-purple-1;
      border-bottom: 1px solid $whe-yellow-1;

      &[target="_blank"] {
        &:after {
          content: "";
          display: inline-block;
          background-image: url("../img/svg/icon-external-hover.svg");
          width: 14px;
          height: 14px;
          margin-left: 5px;
        }
      }

      [target="_blank"] {
        &:after {
          content: "";
          display: inline-block;
          background-image: url("../img/svg/icon-external-hover.svg");
          width: 14px;
          height: 14px;
          margin-left: 5px;
        }
      }
    }
  }

  // external link styles
  &__external {
    border-bottom: 1px solid $whe-purple-1;
    margin-right: 5px;

    &:after {
      content: "";
      display: inline-block;
      background-image: url("../img/svg/icon-external.svg");
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }

    html.no-touchevents &:hover {
      &:after {
        content: "";
        display: inline-block;
        background-image: url("../img/svg/icon-external-hover.svg");
        width: 14px;
        height: 14px;
        margin-left: 5px;
      }
    }
  }
}
