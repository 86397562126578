.richtext {
  // Defaults
  header {
    color: $whe-purple-1;
  }

  .page-title,
  .post-title,
  .event-title {
    padding: 0;
    margin-bottom: $spacer;
  }

  .event-title,
  .post-title {
    margin-bottom: 0;
  }

  .signoff {
    @extend .frank__med-4;
    text-transform: uppercase;
  }

  .module__content {
    background-color: $whe-white;

    // list text indentations level 2
    ul > li > ul {
      text-indent: $spacer;
    }

    // list text indentations level 3
    ul > li > ul > li > ul {
      text-indent: $spacer * 3;
    }

    .module__body {
      figure,
      img {
        max-width: 100% !important;
      }
    }
  }

  // For our Head of School richtext area
  &-featured {
    header {
      color: $whe-black;
    }
  }

  // Specific to the home page
  &-home {
    header {
      color: $whe-white;

      @include media-breakpoint-down(sm) {
        margin-left: 0;
      }
    }

    .module__title {
      // mix-blend-mode: multiply;
      /** Remove for lack of support. Looked where it was working */
      background-color: fade-out($whe-purple-1, 0.1);
    }
  }

  figure,
  .wp-caption {
    max-width: 100%;
  }
}
