// containing all CSS for customizing the events calendar plug-in

// Events list view
// Hide the filter by date field
.tribe-bar-date-filter {
	display: none;
}

.tribe-events-event-meta-container,
.tribe-events-list-event-description {
	.tribe-events-read-more {
		display: none;
	}

	a {
		border-bottom: 1px solid $whe-yellow-1;

		html.no-touchevents &:hover {
			color: $whe-purple-1;
			border-bottom: 1px solid $whe-purple-1;
		}

		&[target="_blank"]:after,
		html.no-touchevents &[target="_blank"]:hover:after {
			display: none;
			border-bottom: none;
		}

		html.no-touchevents &:hover {
			color: $whe-black;
			border-bottom: 1px solid $whe-yellow-1;
		}
	}
}

.tribe-events-calendar td a {
	border-bottom: 1px solid $whe-yellow-1;

	html.no-touchevents &:hover {
		color: $whe-purple-1;
		border-bottom: 1px solid $whe-purple-1;
	}

	font-size: inherit !important;
}

.tribe-event-pager-prev a,
.tribe-event-pager-next a,
.tribe-events-nav-previous a,
.tribe-events-nav-next a,
.tribe-events-back a {
	@extend .btn;
	@extend .btn__color--black;
	@extend .btn__color--purple-hover;
	@extend .btn__icon--fill-yellow-1;
	@extend .btn__icon--direction-left;
	@extend .mb-2;
}

.tribe-events-nav-previous a,
.tribe-events-nav-next a {
	display: flex;
	align-items: center;

	> span {
		display: inline-block;
		width: 13px;
		height: 10px;
		margin: 0 0 0 4px;
		text-indent: -999em;
		background: svg-load("icons-right-arrow.svg", fill=$whe-yellow-1);
		background-repeat: no-repeat;
		background-size: 100%;
	}
}

.tribe-events-nav-previous a {
	> span {
		transform: rotate(180deg);
		margin: 0 4px 0 0;
	}
}

.tribe-address {
	display: block;
	margin-bottom: 20px;
}

.media-details-container {
	[class$="label"] {
		@extend .media-label;
	}
}

.tribe-events-cal-links {
	@extend .btn-group;

	.tribe-events-button:not(:last-child) {
		@extend .mr-4;
	}
}

.tribe-events-gmap,
.tribe-events-button {
	border: none;
	@extend .btn;
	@extend .btn__color--black;
	@extend .btn__color--purple-hover;

	&:after {
		display: none;
	}
}

.tribe-events-list-separator-month {
	@extend .bg__yellow-2;
	@extend .module__title;
	@extend .ml-md-n4;
}

.tribe-event-schedule-details {
	@extend .frank__med-4;
}

// Event detail
.tribe-events-event-meta:first-child {
	border-top: none;
	margin: 0 0 0 0;
}
.single-tribe_events .tribe-events-event-meta {
	margin: 0;
}

// The filtering / search form for events calendar
#tribe-bar-form {
	.form-row input {
		@extend .form-control;
	}

	.tribe-bar-views-option {
		@extend .btn;
		@extend .btn__color--black;
		@extend .btn__color--purple-hover;

		&.active {
			background-color: $whe-white;
			color: $whe-black;

			html.no-touchevents &:hover {
				background-color: $whe-white;
				color: $whe-black;
			}
		}
	}

	.tribe-bar-views-toggle {
		display: none;
	}

	.tribe-bar-active {
		display: none;
	}
}

// filter bar
#tribe_events_filters_wrapper {
	display: none;
}
