.whe-media {
  .group-title {
    @extend .frank__hvy-2;
    color: $whe-purple-1;
  }

  // News + Upcoming schedule media
  &.news,
  &.search,
  &.team-schedule,
  &.events-list,
  &.post-index,
  &.event-detail {
    header {
      margin-bottom: -2em;
    }

    .pattern-row {
      padding-top: 4em;
      padding-bottom: 2em;
    }
  }

  // Team schedule media
  &.team-schedule {
    header {
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
    }

    .pattern-row {
      @include media-breakpoint-down(md) {
        padding-top: 2em;
        padding-bottom: 2em;
      }
    }
  }
}

.media {
  &-item {
    @extend .frank__med-5;
    margin-bottom: $spacer;
  }

  // Default properties
  &-body {
    background-color: $whe-white;
    padding: $spacer;

    a {
      border-bottom: 1px solid $whe-yellow-1;

      html.no-touchevents &:hover {
        color: $whe-purple-1;
      }

      &[target="_blank"] {
        border-bottom: 1px solid $whe-purple-1;
        margin-right: 5px;

        &:after {
          content: "";
          display: inline-block;
          background-image: url("../img/svg/icon-external.svg");
          width: 14px;
          height: 14px;
          margin-left: 5px;
        }

        html.no-touchevents &:hover {
          &:after {
            content: "";
            display: inline-block;
            background-image: url("../img/svg/icon-external-hover.svg");
            width: 14px;
            height: 14px;
            margin-left: 5px;
          }
        }
      }
    }
  }

  &-title {
    @extend .frank__med-3;
    margin-bottom: 10px;
    color: $whe-purple-1;

    a {
      color: $whe-purple-1;

      html.no-touchevents &:hover {
        border-bottom: 1px solid $whe-yellow-1;
      }
    }
  }

  &-label {
    @extend .frank__italic-2;
    margin-bottom: 0;
  }

  &-date-container,
  &-time-container {
    &.inline {
      display: inline-flex;
      align-items: center;
      margin-bottom: 1rem;

      @include media-breakpoint-down(md) {
        display: block;
      }

      > * {
        margin-bottom: 0;

        &:not(:last-child) {
          &:after {
            content: "";
            background-color: $whe-black;
            display: inline-block;
            width: 2px;
            height: 15px;
            margin: 0 15px -3px;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }
      }
    }
  }

  &-time-container {
    &.inline {
      margin-bottom: 0;
    }
  }

  &-day,
  &-month {
    @extend .frank__med-4;
  }

  &-datenum {
    @extend .bask__reg-1;
  }

  &-desc {
    @extend .frank__italic-2;
  }

  &-details {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: 0.3s;

    &.active {
      max-height: 999em;
      padding-top: $spacer;
      opacity: 1;
    }
  }

  &-list {
    list-style: none;
  }

  &-map {
    iframe {
      width: 100%;
    }
  }
}
