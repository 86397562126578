.section-blocks{
    background-color: $whe-purple-1;
    margin-top: 2em;

    .section-pattern{
        position: relative;

        &:before{
            content: "";
            background: url(../img/section-bg.png);
            mix-blend-mode: multiply;
            display: block;
            width: 80%;
            height: calc(100% + 5.5em);
            position: absolute;
            top: -3.75em;
            left: -10em;
            speak: none;
            z-index: 0;
        }
    }
}


.section-block{
    position: relative;
    z-index: 0;

    &:not(:last-child){
        margin-bottom: $spacer * 2.5;
    }

    // Default block properties
    .block-content{
        background-color: fade-out($whe-white, 0.15);
        padding: 15px 5em 15px 15px;

        @include media-breakpoint-down(md){
            padding: 15px;
        }

        &:before{
            left: -1em;
            top: -1em;
            mix-blend-mode: unset;
        }

        video,
        iframe,
        embed,
        object{
            width: 100%;
        }

        img{
            @extend .img-fluid;
            width: 100%;
        }
    }

    .block-media{

        video,
        iframe,
        embed,
        object{
            width: 100%;
        }

        img{
            @extend .img-fluid;
            width: 100%;
        }
    }

    .block-title{
        @extend .frank__hvy-3;
        color: $whe-purple-1;
    }

    .block-body{
        @extend .frank__med-5;
    }

    .block-label{
        @extend .frank__book-3;
        margin-bottom: 0;
    }

    // Block Accordion + Richtext overwrites
    &.block-accordion,
    &.block-richtext,
    &.block-team{

        .block-content{
            padding-right: 15px;
        }
    }

    // #bboxdonation_comment_fldComments {
    //     #bboxdonation_comment_lblComments,
    //     #bboxdonation_comment_txtComments {
    //         margin-left: 0;
    //     }
    // }

    &.block-richtext {
        label,
        textarea {
            margin-left: 0!important;
        }
    }
}