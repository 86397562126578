.cards{

    > .container{
        position: relative;
    }
    
    // Specific rules for fearured contant cards
    &-featured{
        // resets negative margin on headers
        margin-top: 3em;

        @include media-breakpoint-down(sm){
           margin-top: 0;
       }
    }


    &-voices,
    &-news,
    &-related{

        .pattern-row{
            padding: 4em 0 1em;

            @include media-breakpoint-down(sm){
                padding-top: 3em;
            }
        }

        header{
            margin-bottom: -2em;
        }

        &-featured{
            padding-top: 3em;

            header{
                color: $whe-white;

                .module__title{
                    padding-left: 0;
                }
            }

            .bf:before{
                left: -2em;
                top: -1.5em;
                width: calc(100% + 1em);

                @include media-breakpoint-down(md){
                    width: 100%;
                    left: -1em;
                }

                @include media-breakpoint-down(sm){
                    left: 0;
                }
            }

            .af:after{
                left: -4.25em;
                top: -3em;
                width: calc(100% + 2em);

                @include media-breakpoint-down(md){
                    width: 100%;
                    left: -2.25em;
                }

                @include media-breakpoint-down(sm){
                    left: 0;
                }
            }
        }
    }


    &-people{

        header{
            margin-bottom: -2em;
        }
        
        .pattern-row{
            padding-top: 2em;
        }

        .module__title{
            color: $whe-white;
            mix-blend-mode: unset;
            background-color: fade-out($whe-purple-1, 0.15);
        }
    }
}


// Default card rules
.whe-card{
    border: 0;
    background-color: transparent;
    position: relative;

    @include media-breakpoint-down(sm){

        &:not(:last-child){
            margin-bottom: 30px;
        }
    }

    > a{
        display: block;
        transition: 0.2s;
        border: 0;

        html.no-touchevents &:hover{
            background-color: $whe-yellow-1;
            color: $whe-black;
        }
    }

    header{
        color: $whe-white;
    }

    picture{

        img{
            width: 100%;
            height: auto;
        }
    }

    // Default card values
    .card-img{
        border-radius: 0;
    }

    .card-body{
        padding: $spacer 0;
    }

    .card-title,
    .card-name{
        @extend .bask__reg-4;
        margin: 0;
    }

    .card-category{
        @extend .frank__med-5;
        margin: 0;
    }

    .card-prof-title{
        @extend .frank__med-5;
        margin: 0;
    }

    .card-text{
        @extend .frank__med-5;
    }


    // Academic cards
    &__academics{

        > a{
    
            html.no-touchevents &:hover{
                padding: 15px;
            }
        }

        .card-title{
            margin-bottom: 10px;
        }
    }


    // Voices cards
    &__voices{

        > a{
            background-color: $whe-white;
    
            html.no-touchevents &:hover{
                background-color: $whe-yellow-1;
            }
        }

        .card-body{
            padding: 10px;
        }
    }


    // Featued voices cards
    &__voices-featured,
    &__news-featured{

        > a{
    
            html.no-touchevents &:hover{
                background-color: $whe-grey-1;
            }
        }

        .card-body{
            color: $whe-white;
            padding: 10px;
        }
    }


    // CTA cards
    &__cta{
        height: 145px;

        > a{
            position: absolute;
            width: calc(100% -30px);
            height: 100%;

            &:after{
                content: "";
                background-color: fade-out($whe-black, 0.55);
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                speak: none;
                transition: 0.2s;
                z-index: 2;
            }

            html.no-touchevents &:hover{

                &:after{
                    background-color: fade-out($whe-black, 0.85);
                }
            }
        }

        .card-body,
        .card-img{
            padding: $spacer;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 3;
        }

        .card-img{
            padding: 0;
            object-fit: cover;
            object-position: center;
            z-index: 1;
        }
        
        .card-title{
            @extend .frank__hvy-3;
            color: $whe-white;
            z-index: 3;
        }
    }


    // Featured content cards
    &__featured{

        header{
            color: $whe-black;
            margin-top: -3em;

            @include media-breakpoint-down(sm){
                margin-top: 0;
                display: block;
            }
        }

        .module__content{
            background-color: $whe-white;
            padding: 10px;
        }


        // Lead card is the larger card with different markup
        &-lead{
            margin-bottom: 4em;

            .banner{
                display: flex;
                flex-direction: column;
                position: relative;
                height: 760px;

                // Resets negative margin used for module title below
                margin: 60px 0 0;

                @include media-breakpoint-down(md){
                    height: 405px;
                }

                @include media-breakpoint-down(sm){
                    height: auto;
                }

                picture, 
                picture img{
                    position: absolute;
                    width: 100%;
                    height: 100%;

                    @include media-breakpoint-down(sm){
                        position: relative;
                        order: 2
                    }

                    img{
                        object-fit: cover;
                        object-position: center;
                    }
                }

                header{
                    display: block;
                    margin-top: -3em;

                    @include media-breakpoint-down(sm){
                        margin-top: 0;
                        order: 1;
                    }
                }
            }

            .module__content{
                margin-top: -60px;

                @include media-breakpoint-down(sm){
                    margin-top: 0;
                }

                .module__body{
                    background-color: fade-out($whe-white, 0.2);
                    padding: 15px 10px;
                    position: relative;
                    z-index: 1;
                }

                &:before{
                    background-color: fade-out($whe-orange-2, 0.2);
                    content: "";
                    width: calc(100% - 30px);
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: -15px;
                    speak: none;
                    z-index: 0;

                    @include media-breakpoint-down(sm){
                        left: 15px;
                    }
                }
            }
        }
    }

    &__people{

        .card-body{
            background-color: $whe-white;
            padding: 10px;
        }

        .card-name{
            margin-bottom: 5px;
        }

        .card-email,
        .card-phone{
            @extend .frank__book-3;
        }
    }
}