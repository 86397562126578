.hero{
    // default values for all hero's except for home
    background: url("../img/hero-bg.png");
    min-height: 475px;
    margin-top: -$spacer * 2.5;
    position: relative;
    z-index: -1;

    @include media-breakpoint-down(lg){  
        margin-top: -$spacer * 2;
    }

    @include media-breakpoint-down(md){
        min-height: 250px;
        margin-top: -$spacer * 1.5;
    }

    @include media-breakpoint-down(sm){
        min-height: 190px;
    }

    picture,
    video{
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;

         > * {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__home{
        min-height: 810px;
        overflow: hidden;
        margin-bottom: -3em;

        @include media-breakpoint-down(lg){
            min-height: 475px;
        }

        @include media-breakpoint-down(md){
            min-height: 325px;
        }

        picture{
            display: none;

            @include media-breakpoint-down(sm){
                display: block;
            }
        }

        video{
            height: 100%;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            object-fit: cover;
            object-position: center;

            @include media-breakpoint-down(sm){
                display: none;
            }
        }
    }

    &__small{
        min-height: 135px;
        overflow: hidden;
    }
}