.toggle{
    // Toggles inherit all button rules by default
    @extend .btn;

    svg{
        width: 13px;
        height: 11px;
        margin: 0;
        transition: 0.2s;
    }

    @each $color, $color-val in ($btn-colors) {
        // Applies background colors to buttons
        &__color--#{$color} {
            background-color: #{$color-val};
        }

        // background colors on hover
        &__color--#{$color}-hover {

            html.no-touchevents &:hover,
            &.active{
                background-color: #{$color-val};
            }
        }

        // Applies fill colors to icon / svg's
        &__icon--fill-#{$color}{

            svg {
                fill: #{$color-val};
            }
        }

        &__icon--#{$color}-hover{

            html.no-touchevents &:hover{
                svg {
                    fill: #{$color-val};
                }
            }
        }
    }

    @each $direction, $direction-val in ($arrow-diretions) {
        &__icon--direction-#{$direction} {

            svg {
                transform: rotate($direction-val);
            }
        }
    }


    // Pager toggles
    &__pager{
        @extend .frank__cond-2;
        line-height: 1;
        padding: 10px;
        min-width: 40px;
        height: 40px;
    }


    // Main menu toggle
    &__menu-main{
        // extend rules from the pager toggles / buttons.
        @extend .toggle__pager;
        padding: 8px;

        svg{
            width: 22px;
            height: 18px;
        }
    }


    // Search toggles
    &__search,
    &__search-submit,
    &__search-close{
        // extend rules from the pager toggles / buttons.
        @extend .toggle__pager;

        svg{
            width: 20px;
            height: 20px;
        }
    }


    // Gallery toggle
    &__gallery{
        padding: 0;

        svg{
            width: 45px;
            height: 37px;
        }
    }


    // Accordion toggles
    // Handles both accordion module toggle and show / hide toggle for calendar directions
    &__accordion{
        @extend .frank__med-4;
        border-bottom: 1px solid $whe-purple-1;
        border-top: 1px solid $whe-purple-1;
        color: $whe-purple-1;
        display: block;
        padding: 15px 30px 15px 0;
        position: relative;
        text-align: left;
        width: 100%;

        svg{
            margin: 0;
            position: absolute;
            right: 10px;
            top: calc(50% - 6px);
            width: 16px;
            height: 13px;
            transform: rotate(90deg);
            transition: 0.2s;
        }

        html.no-touchevents &:hover{
            color: $whe-purple-1;
        }

        &:focus{
            color: $whe-purple-1;
        }

        &.active{
            color: $whe-purple-1;

            svg{
                transform: rotate(-90deg);
            }
        }
    }


    // modifier for calendar directions
    &__accordion-directions{

        svg{
            margin: 0 0 3px 4px;
        }

        &.active{

            svg{
                transform: rotate(-90deg);
            }
        }
    }


    // Calendar view toggle
    &__calendar{

        &.active,
        &:focus{
            background-color: $whe-white;
            color: $whe-black;

            html.no-touchevents &:hover{
                background-color: $whe-white;
                color: $whe-black;
            }
        }
    }


    // Sub-nav toggle (reveals menu items for tablet / mobile)
    // Is only revealed on tablet / mobile
    &__sub-nav,
    &__section-nav{
        padding: 0;
        width: 35px;
        height: 35px;
        vertical-align: bottom;

        svg{
            width: 16px;
            height: 13px;
        }

        &.active{

            svg{
                transform: rotate(-90deg);
            }
        }
    }
}