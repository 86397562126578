// Contains all rules applicable to form related elements
input{

    // Ensure interactive form elements get our button styles
    &[type="button"],
    &[type="submit"],
    &[type="reset"]{
        @extend .btn;
    }
}


// Persistent search, located in header
#form-search{

    .form{

        input[type="search"]{
            @extend .frank__med-5;
            background-color: $whe-grey-2;
            border: 0;
            color: $whe-white;
            height: calc(2.25rem + 4px);
            margin-right: -1px;

            &::placeholder{
                color: $whe-white;
            }

            @include media-breakpoint-down(md){
                display: none;
            }
        }

        // Search toggle is hidden by default and shown for mobile. 
        .toggle__search{
            display: none;

            @include media-breakpoint-down(md){
                display: block;
            }
        }

        .toggle__search-submit{
            display: block;

            @include media-breakpoint-down(md){
                display: none;
            }
        }

        .toggle__search-close{
            display: none;
        }

        // Active state for the search form
        // Toggling active state only occurs on mobile
        &.active{

            // Hides the default search field group when active / mobile
            > .form-group{
                @include media-breakpoint-down(md){
                    display: none;
                }
            }

            input[type="search"]{
                display: block;
            }

            .toggle__search{
                @include media-breakpoint-down(md){
                    display: none;
                }
            }

            .toggle__search-submit{
                @include media-breakpoint-down(md){
                    display: block;
                }
            }

            .toggle__search-close{
                @include media-breakpoint-down(md){
                    display: block;
                }
            }
        }
    }

    &-mobile{
        max-height: 0;
        opacity: 0;
        transition: 0.2s;
        position: relative;
        

        body.search-active &{
            @include media-breakpoint-down(md){
                max-height: 999em;
                opacity: 1;
                z-index: 12;
            }
        }
    }
}