body{
    @extend .frank__book-2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
}


a{
    color: $whe-black;
    text-decoration: none;

    &:hover,
    &:focus{
        text-decoration: none;
        color: $whe-purple-1;
    }
}


ul,
ol{
    padding: 0;
    list-style-position: inside;
}


blockquote{
    @extend .bask__italic-1;
    color: $whe-yellow-1;
    padding: 0;
    border: 0;
}


cite,
.whe-cite{
    @extend .frank__med-4;
    color: $whe-purple-1;
    display: block;
    text-transform: uppercase;
    font-style: normal;
}


figure{
    padding-left: 3.75rem;

    @include media-breakpoint-down(md){
        padding-left: 0;
    }

    img{
        width: 100%;
        height: auto;
    }

    figcaption{
        @extend .frank__med-5;
        margin-top: -1.875rem;
        margin-left: -1.25rem;
        margin-right: 1.25rem;
        background-color: fade-out($whe-purple-1, 0.2);
        color: $whe-white;
        padding: 3em 2em;
        position: relative;
        z-index: 2;

        @include media-breakpoint-down(md){
            margin: 0;
        }
    }
}