.font-sans {
  font-family: $font-family-sans-serif;
}

.font-serif {
  font-family: $font-family-serif;
}


// Baskerville Regular
.bask__reg-1 {
  @include font-vars($font-family-serif, $font-weight-regular, 68px, 68px);
}

.bask__reg-2 {
  @include font-vars($font-family-serif, $font-weight-regular, 48px, 56px);

  @include media-breakpoint-down(sm){
    @include font-vars($font-family-serif, $font-weight-regular, 30px, 36px);
  }
}

.bask__reg-3 {
  @include font-vars($font-family-serif, $font-weight-regular, 24px, 40px);
}

.bask__reg-4 {
  @include font-vars($font-family-serif, $font-weight-regular, 18px, 22px);
}

.bask__reg-5 {
  @include font-vars($font-family-serif, $font-weight-regular, 16px, 22px);
}

// Baskerville Italic
.bask__italic-1 {
  @include font-vars($font-family-serif, $font-weight-regular, 57px, 74px);
  font-style: italic;
}


// Franklin Gothic Heavy
.frank__hvy-1 {
  @include font-vars($font-family-sans-serif, $font-weight-heavy, 48px, 58px);
}

.frank__hvy-2 {
  @include font-vars($font-family-sans-serif, $font-weight-heavy, 32px, 40px);

  @include media-breakpoint-down(sm){
    @include font-vars($font-family-sans-serif, $font-weight-heavy, 24px, 30px);
  }
}

.frank__hvy-3 {
  @include font-vars($font-family-sans-serif, $font-weight-heavy, 25px, 30px);
}

.frank__hvy-4 {
  @include font-vars($font-family-sans-serif, $font-weight-heavy, 18px, 30px);
}


// Franklin Gothic Medium
.frank__med-1 {
  @include font-vars($font-family-sans-serif, $font-weight-medium, 36px, 60px);
}

.frank__med-2 {
  @include font-vars($font-family-sans-serif, $font-weight-medium, 32px, 32px);

  @include media-breakpoint-down(sm){
    @include font-vars($font-family-sans-serif, $font-weight-medium, 18px, 30px);
  }
}

.frank__med-3 {
  @include font-vars($font-family-sans-serif, $font-weight-medium, 24px, 28px);

  @include media-breakpoint-down(sm){
    @include font-vars($font-family-sans-serif, $font-weight-medium, 18px, 24px);
  }
}

.frank__med-4 {
  @include font-vars($font-family-sans-serif, $font-weight-medium, 18px, 30px);
}

.frank__med-5 {
  @include font-vars($font-family-sans-serif, $font-weight-medium, 14px, 20px);
}

// Franklin Gothic Demi
.frank__demi-1{
  @include font-vars($font-family-sans-serif, $font-weight-demi, 18px, 30px);
}

// Franklin Gothic Condensed
.frank__cond-1 {
  @include font-vars($font-family-condensed, $font-weight-demi, 24px, 30px);
}

.frank__cond-2 {
  @include font-vars($font-family-condensed, $font-weight-medium, 18px, 30px);
}

.frank__cond-3 {
  @include font-vars($font-family-condensed, $font-weight-regular, 18px, 30px);
}


// Franklin Book
.frank__book-1 {
  @include font-vars($font-family-sans-serif, $font-weight-book, 28px, 36px);
}

.frank__book-2 {
  @include font-vars($font-family-sans-serif, $font-weight-book, 18px, 30px);
}

.frank__book-3 {
  @include font-vars($font-family-sans-serif, $font-weight-book, 14px, 20px);
}


// Franklin Gothic Book Italic
.frank__italic-1 {
  @include font-vars($font-family-sans-serif, $font-weight-book, 18px, 30px);
  font-style: italic;
}

.frank__italic-2 {
  @include font-vars($font-family-sans-serif, $font-weight-book, 14px, 20px);
  font-style: italic;
}