// OVERRIDE CORE VARIABLES AS NEEDED.
// You can use a unique value for an existing variable by defining it here, before mixins.less is imported.

// Colors
$whe-purple-1: #3D1A79;
$whe-yellow-1: #fcba30;
$whe-yellow-2: #fed685;
$whe-white: #ffffff;
$whe-grey-1: #4a4a4a;
$whe-grey-2: #767676;
$whe-grey-3: #d8d8d8;
$whe-black: #000000;
$whe-orange-1: #d9553e;
$whe-orange-2: #f58c60;
$whe-blue-1: #819ec6;
$whe-blue-2: #9cc2dc;
$whe-sage-1: #a6bd8a;

// Button background colors
$btn-colors: () !default;
$btn-colors: map-merge((
    'purple': $whe-purple-1,
    'yellow-1': $whe-yellow-1,
    'yellow-2': $whe-yellow-2,
    'white': $whe-white,
    'grey-1': $whe-grey-1,
    'grey-2': $whe-grey-2,
    'grey-3': $whe-grey-3,
    'black': $whe-black,
    'orange-1': $whe-orange-1,
    'orange-2': $whe-orange-2,
    'blue-1': $whe-blue-1,
    'blue-2': $whe-blue-2,
    'sage-1': $whe-sage-1,
    'none': 'transparent',
), $btn-colors);

// SCSS color map
$whe-colors: (
    --whe-purple-1: #3D1A79,
    --whe-yellow-1: #fcba30,
    --whe-yellow-2: #fed685,
    --whe-white: #ffffff,
    --whe-grey-1: #4a4a4a,
    --whe-grey-2: #767676,
    --whe-grey-3: #d8d8d8,
    --whe-black: #000000,
    --whe-orange-1: #d9553e,
    --whe-orange-2: #f58c60,
    --whe-blue-1: #819ec6,
    --whe-blue-2: #9cc2dc,
    --whe-sage-1: #a6bd8a,
);

// CSS Variable colors
:root{
    @each $var, $value in $whe-colors {
        #{$var}: $value;
    }
}


// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded:    false;
$border-radius:     0;
$border-radius-lg:  0;
$border-radius-sm:  0;


// Arrow directions based on asset pointing to the right:
// Button background colors
$arrow-diretions: () !default;
$arrow-diretions: map-merge((
    'right': 0deg,
    'down': 90deg,
    'left': 180deg,
    'up': 270deg,
), $arrow-diretions);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px
);
@include _assert-ascending($container-max-widths, "$container-max-widths");


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem * 1.25; //20px
$spacers: ();
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25), 			// 5px
  2: ($spacer * .5), 			// 10px
  3: ($spacer), 				// 20px
  4: ($spacer * 1.5), 			// 30px
  5: ($spacer * 3), 			// 60px
  6: ($spacer * 3.75)           // 75px
), $spacers);


// Paragraphs
//
// Style p element.
$paragraph-margin-bottom:   ($spacer / 2);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.
// Common examples
$grid-gutter-width:		    30px; // unchanged from default, but can change here.
$grid-column-count:		    12; // unchanged from default, but can change here.

$font-family-sans-serif:    'FranklinGothic', Arial, sans-serif;
$font-family-condensed:     'FranklinGothic Condensed', sans-serif;
$font-family-serif:         'Baskerville', serif;

$font-family-base:          $font-family-sans-serif;

$whe-font-size-base-set: 	1rem; //16px
$font-size-base:            $whe-font-size-base-set * 1.125; //~18px

$font-weight-book:          300;
$font-weight-regular:       400;
$font-weight-medium:        500;
$font-weight-demi:          700;
$font-weight-heavy:         900;

$font-weight-base:          $font-weight-regular;
$line-height-base:          $whe-font-size-base-set * 1.875 !default; //~30px

$h1-font-size:              $whe-font-size-base-set * 3; //~48px
$h2-font-size:              $whe-font-size-base-set * 3; //~48px
$h3-font-size:              $whe-font-size-base-set * 2; //~32px
$h4-font-size:              $whe-font-size-base-set * 1.5; //~24px
$h5-font-size:              $font-size-base * 1; //~18px
$h6-font-size:              $whe-font-size-base-set; //~16px

$headings-margin-bottom:    ($spacer / 2);