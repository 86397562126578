// Create variable to hold "fonts" folder path.
$sans-path: "../fonts/franklin-gothic";
$serif-path: "../fonts/new-baskerville";

// Baskerville Regular
@font-face {
  font-family: 'Baskerville';
  font-weight: $font-weight-regular;
  font-style: normal;
  src:url("../fonts/new-baskerville/NewBaskerville-Regular.eot?#iefix") format("eot"),
  url("../fonts/new-baskerville/NewBaskerville-Regular.woff") format("woff"),
  url("../fonts/new-baskerville/NewBaskerville-Regular.otf") format("opentype"),
  url("../fonts/new-baskerville/NewBaskerville-Regular.svg#Baskerville") format("svg");
}

// Baskerville italic
@font-face {
  font-family: 'Baskerville';
  font-weight: $font-weight-regular;
  font-style: italic;
  src:url("../fonts/new-baskerville/NewBaskerville-It.eot?#iefix") format("eot"),
  url("../fonts/new-baskerville/NewBaskerville-It.woff") format("woff"),
  url("../fonts/new-baskerville/NewBaskerville-It.otf") format("opentype"),
  url("../fonts/new-baskerville/NewBaskerville-It.svg#Baskerville") format("svg");
}

// Franklin Medium
@font-face {
  font-family: 'FranklinGothic';
  font-weight: $font-weight-medium;
  font-style: normal;
  src:url("../fonts/franklin-gothic/franklingothic-med.eot?#iefix") format("eot"),
  url("../fonts/franklin-gothic/franklingothic-med.woff") format("woff"),
  url("../fonts/franklin-gothic/franklingothic-med.otf") format("opentype"),
  url("../fonts/franklin-gothic/franklingothic-med.svg#Baskerville") format("svg");
}

// Franklin Demi
@font-face {
  font-family: 'FranklinGothic';
  font-weight: $font-weight-demi;
  font-style: normal;
  src:url("../fonts/franklin-gothic/franklingothic-demi.eot?#iefix") format("eot"),
  url("../fonts/franklin-gothic/franklingothic-demi.woff") format("woff"),
  url("../fonts/franklin-gothic/franklingothic-demi.otf") format("opentype"),
  url("../fonts/franklin-gothic/franklingothic-demi.svg#Baskerville") format("svg");
}

// Franklin Heavy
// @font-face {
//   font-family: 'FranklinGothic';
//   font-weight: $font-weight-heavy;
//   font-style: normal;
//   src:url("../fonts/franklin-gothic/franklingothic-hvy.eot?#iefix") format("eot"),
//   url("../fonts/franklin-gothic/franklingothic-hvy.woff") format("woff"),
//   url("../fonts/franklin-gothic/franklingothic-hvy.otf") format("opentype"),
//   url("../fonts/franklin-gothic/franklingothic-hvy.svg#Franklin") format("svg");
// }


// Franklin Heavy
@font-face {
  font-family: 'FranklinGothic';
  font-weight: $font-weight-heavy;
  font-style: normal;
  src:url("../fonts/franklin-gothic/franklingothic-hvy-webfont.eot?#iefix") format("eot"),
  url("../fonts/franklin-gothic/franklingothic-hvy-webfont.woff") format("woff"),
  url("../fonts/franklin-gothic/franklingothic-hvy-webfont.otf") format("opentype"),
  url("../fonts/franklin-gothic/franklingothic-hvy-webfont.svg#Franklin") format("svg");
}

// Franklin Demi Condensed
// @font-face {
//   font-family: 'FranklinGothic Condensed';
//   font-weight: $font-weight-demi;
//   font-style: normal;
//   src:url("../fonts/franklin-gothic/franklingothic-demicd.eot?#iefix") format("eot"),
//   url("../fonts/franklin-gothic/franklingothic-demicd.woff") format("woff"),
//   url("../fonts/franklin-gothic/franklingothic-demicd.otf") format("opentype"),
//   url("../fonts/franklin-gothic/franklingothic-demicd.svg#Franklin") format("svg");

  // Franklin Demi Condensed
@font-face {
  font-family: 'FranklinGothic Condensed';
  font-weight: $font-weight-demi;
  font-style: normal;
  src:url("../fonts/franklin-gothic/franklingothic-demi-webfont.eot?#iefix") format("eot"),
  url("../fonts/franklin-gothic/franklingothic-demi-webfont.woff") format("woff"),
  url("../fonts/franklin-gothic/franklingothic-demi-webfont.otf") format("opentype"),
  url("../fonts/franklin-gothic/franklingothic-demi-webfont.svg#Franklin") format("svg");
}

// Franklin Condensed
@font-face {
  font-family: 'FranklinGothic Condensed';
  font-weight: $font-weight-regular;
  font-style: normal;
  src:url("../fonts/franklin-gothic/franklingothic-cd.eot?#iefix") format("eot"),
  url("../fonts/franklin-gothic/franklingothic-cd.woff") format("woff"),
  url("../fonts/franklin-gothic/franklingothic-cd.otf") format("opentype"),
  url("../fonts/franklin-gothic/franklingothic-cd.svg#Franklin") format("svg");
}

// Franklin Medium Condensed
@font-face {
  font-family: 'FranklinGothic Condensed';
  font-weight: $font-weight-medium;
  font-style: normal;
  src:url("../fonts/franklin-gothic/franklingothic-medcd.eot?#iefix") format("eot"),
  url("../fonts/franklin-gothic/franklingothic-medcd.woff") format("woff"),
  url("../fonts/franklin-gothic/franklingothic-medcd.otf") format("opentype"),
  url("../fonts/franklin-gothic/franklingothic-medcd.svg#Franklin") format("svg");
}

// Franklin Book
@font-face {
  font-family: 'FranklinGothic';
  font-weight: $font-weight-book;
  font-style: normal;
  src:url("../fonts/franklin-gothic/franklingothic-bookit.eot?#iefix") format("eot"),
  url("../fonts/franklin-gothic/franklingothic-bookit.woff") format("woff"),
  url("../fonts/franklin-gothic/franklingothic-bookit.otf") format("opentype"),
  url("../fonts/franklin-gothic/franklingothic-bookit.svg#Franklin") format("svg")
};

// Franklin Book Italic
@font-face {
  font-family: 'FranklinGothic';
  font-weight: $font-weight-book;
  font-style: italic;
  src:url("../fonts/franklin-gothic/franklingothic-bookit.eot?#iefix") format("eot"),
  url("../fonts/franklin-gothic/franklingothic-bookit.woff") format("woff"),
  url("../fonts/franklin-gothic/franklingothic-bookit.otf") format("opentype"),
  url("../fonts/franklin-gothic/franklingothic-bookit.svg#Franklin") format("svg")
};