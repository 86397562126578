.module {
  &__title {
    @extend .bask__reg-2;
    display: inline-block;
    padding: $spacer / 2 $spacer;
    margin: 0;
    position: relative;
    z-index: 5;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    &.post-title,
    &.event-title {
      @extend .frank__hvy-1;
    }
  }

  &__lead {
    @extend .frank__book-1;
    color: $whe-grey-2;
  }

  // Controls appearance of elements in richtext fields such as the_content();
  &__body {
    @extend .frank__med-4;

    // All first level children get more margin bottom
    > *:not(:last-child) {
      margin-bottom: $spacer;
    }

    // Heading level rules
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $whe-purple-1;
    }

    h1 {
      @extend .page-title;
      margin-bottom: inherit;
    }

    h2 {
      @extend .frank__hvy-2;
    }

    h3 {
      @extend .frank__med-2;
    }

    h4 {
      @extend .frank__cond-1;
    }

    h5 {
      @extend .frank__med-3;
    }

    h6 {
      @extend .frank__med-4;
    }

    p,
    li {
      a:not(.btn) {
        border-bottom: 1px solid $whe-yellow-1;

        html.no-touchevents &:hover {
          color: $whe-purple-1;
          border-bottom: 1px solid $whe-purple-1;
        }

        &[target="_blank"] {
          border-bottom: 1px solid $whe-purple-1;
          margin-right: 5px;

          &:after {
            content: "";
            display: inline-block;
            background-image: url("../img/svg/icon-external.svg");
            width: 14px;
            height: 14px;
            margin-left: 5px;
          }

          html.no-touchevents &:hover {
            &:after {
              content: "";
              display: inline-block;
              background-image: url("../img/svg/icon-external-hover.svg");
              width: 14px;
              height: 14px;
              margin-left: 5px;
            }
          }
        }
      }
    }

    strong,
    b {
      @extend .frank__demi-1;
    }

    blockquote {
      &:not(:first-child) {
        margin: $spacer * 1.25 0;
      }
    }

    img {
      @extend .img-fluid;
    }

    iframe {
      @extend .embed-responsive;
    }
  }
}
