.gallery{
    padding-top: 50px;
    overflow: hidden;

    .module__title{
        color: $whe-white;
        padding-left: 0;
    }

    &-container,
    &-inner{
        position: relative;

        &:before,
        &:after{
            content: "";
            display: block;
            position: absolute;
            mix-blend-mode: multiply;
        }
    }

    &-container{
        padding: 30px 0 60px; 

        &:before{
            background-color: fade-out($whe-yellow-1, 0.5);
            left: -55px;
            top: -30px;
            width: calc(100% + 90px);
            height: calc(100% - 20px);
        }

        &:after{
            background-color: $whe-grey-3;
            width: calc(100% + 200px);
            height: calc(100% - 60px);
            top: 20px;
            left: -105px;
            z-index: -1;
        }
    }

    &-inner{

        &:before{
            background-color: fade-out($whe-orange-1, 0.5);
            left: -20px;
            top: -110px;
            width: calc(100% + 40px);
            height: calc(100% + 200px);
        }
    }

    &-item{
        position: relative;
        display: block;
        z-index: 5;
        transition: 0.2s ease-in;

        figure{
            margin: 0;
            padding: 0;
            height: 100%;

            img{
                height: 100%;
                object-fit: cover;
            }

            figcaption{
                display: none;
            }
        }

        &:after{
            content: "";
            background-color: $whe-grey-1;
            display: block;
            position: absolute;
            left: 15px;
            top: 0;
            width: calc(100% - 30px);
            height: calc(100%);
            opacity: 0;
            transition: 0.2s;
            speak: none;
            z-index: -1;
        }

        html.no-touchevents &:hover{

            &:after{
                left: 0;
                top: 15px;
                opacity: 1;
            }
        }
    }

    .toggle{
        flex-grow: 0;
        margin-right: 10px;
    }
}