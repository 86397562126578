// Rules for our events filter module
.whe-events{

    .btn{

        &.active{
            background-color: $whe-white;
            color: $whe-black;

            html.no-touchevents &:hover{
                background-color: $whe-white;
                color: $whe-black;
            }
        }
    }
}

.group-title{
    @extend .frank__cond-1;
    color: $whe-purple-1;
}


// Handles the specific fields on event detail pages
.event{

    &-date{
        color: $whe-black;
    }
}