// Accounts for our various marks, which will all carry the same class of .logo but will change depending on applied modifiers
.logo{

    svg{
        @extend .img-fluid;
    }

    &__main{

        svg{

            // Set to designs w/h for mobile
            @include media-breakpoint-down(md){
                max-width: unset;
                width: 270px;
            }

            #logo-text{
                
                @include media-breakpoint-down(sm){
                    display: none;
                }

                // For mobile menu, fill color is displayed and white
                body.overlay-menu-active & path{

                    @include media-breakpoint-down(md){
                        fill: $whe-white;
                    }
                }
            }
        }
    }

    &__main-footer{
        display: inline-block;
        margin-top: -1px;
    }
}


body.overlay-menu-active,
body.overlay-gallery-active{
    height: 100%;
    overflow-y: hidden;
}


// Site header
#site-header{
    position: relative;

    #container-brand{

        body.overlay-menu-active &{
            z-index: 15;
            position: relative;
            background-color: $whe-purple-1;
        }
    }

    #utilities{

        .menu__utility-navigation{
            margin-right: $spacer / 1.5;

            @include media-breakpoint-down(md){
                display: none;
            }
        }

        #form-search{
            margin-right: 0;

            @include media-breakpoint-down(md){
                margin-right: $spacer / 1.5;
            }
        }

        .toggle__menu-main{
            display: none;
    
            @include media-breakpoint-down(md){
                display: block;
                margin-right: 0;
            }
        }
    }

    #container-menus{
        position: relative;
        margin-top: -80px;

        @include media-breakpoint-down(md){
            margin-top: 0;
        }

        body.home &{
           position: absolute;
           top: 60%;
           right: 0;
        }

        .menu{
            position: relative;

            @include media-breakpoint-down(md){
                display: none;
            }

            &__main-navigation{
                z-index: 2;
            }

            &__secondary-navigation{
                margin-top: -$spacer / 1.25;
                z-index: 1;
            }
        }
    }
}


// Site footer
#site-footer{
    background-color: $whe-purple-1;
    overflow: hidden;

    #container{

        &-menus-footer{
            background: url("../img/footer-bg.png");
            margin-top: -$spacer * 1.5;

            @include media-breakpoint-down(sm){
                margin-top: 0;
            }
        }

        &-info-footer{
            @extend .bask__reg-3;
            color: $whe-white;

            @include media-breakpoint-down(sm){
                font-size: $font-size-base;
                line-height: $line-height-base;
            }

            address{
                @extend .frank__med-4;
            }

            a{
                color: $whe-white;

                html.no-touchevents &:hover{
                    border-bottom: 1px solid $whe-white;
                }
            }
        }

        &-menu-footer{
            background-color: $whe-grey-1;
        }
    }

    .menu:not(.menu__footer-navigation){
        background-color: transparent;
    }
}


// Overlay / modal 
.overlay{
    padding: 150px 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: -100vh;
    right: 0;
    left: 0;
    transition: 0.2s;
    opacity: 0;
    z-index: -1;
    overflow-y: scroll;

    &__menu{
        background-color: $whe-purple-1;
    }

    &__gallery{
        background-color: fade-out($whe-black, 0.1);
    }

    // active state for mobile
    body.overlay-menu-active &__menu{
        top: 0;
        opacity: 1;
        z-index: 10;

        &__menu{
            display: none;

            @include media-breakpoint-down(md){
                display: block
            }
        }
    }

    body.overlay-gallery-active &__gallery{
        top: 0;
        opacity: 1;
        z-index: 10;
    }
}

body.search{

    .media-date{
        display: none;
    }
}


body.search-active{
    
    &.overlay-menu-active .overlay__menu{
        padding-top: 220px;
    }
}