.btn {
	padding: 8px 10px;
	color: $white;
	border: 0;
	border-radius: 0;

	svg {
		width: 10px;
		height: 13px;
		margin: 0 0 3px 4px;
	}

	&__lg{
		padding: 14px 20px;
	}

	> a{
		color: $white;
	}

	@each $color, $color-val in ($btn-colors) {
		// Applies background colors to buttons
		&.btn__color--#{$color} {
			background-color: #{$color-val};
		}

		// background colors on hover
		&.btn__color--#{$color}-hover {

			html.no-touchevents &:hover,
			&.active{
				background-color: #{$color-val};
			}
		}

		// Applies fill colors to icon / svg's
		&.btn__icon--fill-#{$color}{

			svg {
				fill: #{$color-val};
			}
		}

		&.btn__icon--fill-#{$color}-hover{

			svg {
				fill: #{$color-val};
			}
		}
	}

	@each $direction, $direction-val in ($arrow-diretions) {
		&.btn__icon--direction-#{$direction} {

			svg {
				transform: rotate($direction-val)
			}
		}
	}

	html.no-touchevents &:hover,
	&.active,
	&:visited,
	&:focus {
		text-decoration: none;
		color: $whe-white;
	}

	// manipulating the close icon to create a plus / add icon
	&__icon-add{

		svg{
			transform: rotate(45deg);
			margin-right: 4px;
		}
	}
}

.btn-group{
	a{
		@extend .btn;
	}
}