@each $color, $color-val in ($btn-colors) {

    // $color = color key / label from list
    // $color-val = hex value

    // Applies background colors to headers
    .bg__#{$color} {
        mix-blend-mode: multiply;
        background-color: #{$color-val};
    }

    .bg__#{$color}-solid {
        background-color: #{$color-val};
    }
}


// A wrapper class for applying section bg pattern
.pattern-row{
    background-image: url(../img/section-bg.png);
    position: relative;

    &.pattern-square{
        background-image: url(../img/section-square-bg.png);
    }
}


// Wrapper class for background overlays
.bg-overlay{
    position: relative;

    @each $color, $color-val in ($btn-colors) {
        
        &.bf-#{$color}:before{
            mix-blend-mode: multiply;
            background-color: #{$color-val};
        }

        &.af-#{$color}:after{
            mix-blend-mode: multiply;
            background-color: #{$color-val};
        }
    }

    &.bf:before,
    &.af:after{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        speak: none;
        z-index: -1;
    }
}

// Full width images, used in BG's, go full width of container
.bg-image{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
    object-position: center;
}