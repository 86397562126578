.pager{

    > *:not(:last-child){
        margin-right: 5px;
    }

    @include media-breakpoint-down(sm){
        .toggle__pager-next,
        .toggle__pager-previous{
            display: none;
        }
    }
}