.alert {
  display: none;

  .module__title {
    @extend .frank__hvy-2;
  }

  .module__body {
    a {
      border-bottom: 1px solid $whe-black;
      color: $whe-black;

      html.no-touchevents &:hover {
        color: $whe-purple-1;
        border-bottom: 1px solid $whe-purple-1;
      }

      // When section link is the link itself
      &[target="_blank"] {
        &:after {
          content: "";
          display: inline-block;
          background-image: url("../img/svg/icon-external.svg");
          width: 14px;
          height: 14px;
          margin-left: 5px;
        }
      }
    }
  }

  body.overlay-menu-active & {
    display: none !important;
  }
}
