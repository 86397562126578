.accordion{

    &-item{

        &:not(:last-child){
            
            .toggle{
                border-bottom: 0;
            }
        }
    }

    &-title{
        margin: 0;
    }

    &-content{

        &.show{
            border-top: 1px solid $whe-purple-1;
        }
    }

    &-body{
        padding: 15px 0;
    }
}