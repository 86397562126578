.whe-carousel {
    flex-wrap: nowrap;
    overflow: hidden;
}

.carousel{

    figure{
        padding: 0;
    }
}